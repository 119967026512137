/* eslint-disable consistent-return */
import { logger } from './logger';
import { isJSON } from '../utils/isJSON';

export const interceptorSwitch = res => {
  if (!res) return;

  const safeStringify = data => {
    try {
      return JSON.stringify(data);
    } catch (e) {
      return '';
    }
  };

  const { config, request, response } = res;
  const errorData = response && response.data ? response.data : res.data;

  const baseFields = {
    request_id: config?.headers?.request_id || 'undefined',
    is_Not_JSON: !isJSON(safeStringify(errorData)),
    is_string_response: typeof errorData === 'string',
    typeEnv: 'ssr'
  };


  if (!errorData) {
    return logger({
      additionalFields: {
        ...baseFields,
        endpoint: request.path,
      },
      level: 'error',
      message: 'No data received in response'
    });
  }

  if (errorData.error) {
    return logger({
      additionalFields: {
        ...baseFields,
        endpoint: request.path,
        error_code: errorData.error.code,
      },
      level: 'error',
      message: errorData.error.message || 'Unknown error'
    });
  }

  return logger({
    additionalFields: {
      ...baseFields,
      endpoint: request.path
    },
    level: 'error',
    message: 'An error occurred'
  });
};